import img1 from "../assets/kids.jpg";
import img2 from "../assets/bgimg1.jpeg";
import img3 from "../assets/pool.jpg";
import img4 from "../assets/boy.jpg";
import img5 from "../assets/older.jpeg";
export const data = [
  {
    id: 1,
    number: "01",
    imgSrc: img1,
    destTitle: "Anfänger - Schwimmkurse",
    age: "ab 5 Jahre",
    duration1: "45 Minuten",
    lessons: "10 Trainingseinheiten",
    kids: "von 8-12 Kinder",
    preis1: "Preis  165 Bad Sassendorf / 180 Möhnesee",
    description: [
      {
        subTitle: "Anforderungen zum Schwimmabzeichen Seepferdchen",
        about: `Während unseres Anfänger-Schwimmkurses werden die Kinder auf 
        spielerische Art und Weise, das sichere und eigenständige Bewegen, 
        im Wasser erarbeiten. Inhaltlich folgen nach der spielerischen Wassergewöhnung 
        das Atmen, Tauchen, Springen, Gleiten und natürlich die ersten Arm und 
        Beinbewegungen des Schwimmens. Der Kurs wird voraussichtlich mit dem 
        Schwimmabzeichen Seepferdchen abgeschlossen.`,
        rouls: [
          `Ein Sprung vom Beckenrand mit anschließendem 25 m
      Schwimmen (ohne Schwimmhilfe) in eine Schwimmart
      in Bauch oder Rückenlage
      ( Grobform, während des Schwimmens in Bauchlage
      erkennbar ins Wasser ausatmen)`,
          `Heraufholen eines Tauchrings mit den Händen aus schultertiefen Wasser`,
          `3-5 Baderegeln, die Sie auch auf meiner Homepage finden können`,
        ],
      },
    ],
  },
  {
    id: 2,
    number: "02",
    imgSrc: img2,
    destTitle: "Einzelschwimmunterricht für Kinder und Erwachsene",
    age: "",
    duration1: "  1 x 45 Minuten",
    duration2: "  5 x 45 Minuten",
    kids: "",
    preis1: " Preis 40",
    preis2: " Preis 175",
    description: [
      {
        subTitle: "",
        about: `Im Einzelunterricht kann ich, als persönlicher Schwimmlehrer, noch effektiver und individueller auf die  Bedürfnisse des Kindes eingehen, als im Gruppenunterricht.
        Der Einzelunterricht ist besonders vorteilhaft bei Vorliegen von: `,
        rouls: [
          `Konzentrationsschwäche`,
          `Koordinationsschwäche`,
          `Ängste im Wasser`,
        ],
      },
      {
        subTitle: "",
        about: `Der Einzelunterricht bietet zusätzlich die Möglichkeit, das Schwimmen innerhalb kürzester Zeit zu lernen.`,
        rouls: [],
      },
      {
        subTitle: "",
        about: `Desweiteren biete ich meinen Einzelunterricht für Erwachsene an, die ihre technik verbessern wollen oder  das Schwimmen zu erlernen, oder sich unwohl fühlen als Erwachsener an einem Schwimmkurs teilzunehmen.`,
        rouls: [],
      },
    ],
  },
  {
    id: 3,
    number: "03",
    imgSrc: img3,
    destTitle: "Bronze, Silber - und Goldkurs",
    age: "von 6 - 14 Jahre",
    duration1: "45 Minuten",
    lessons: "8 Trainingseinheiten",
    kids: "von 8-12 Kinder",
    preis1: "Preis 155 Bad Sassendorf / 170 Möhnesee",
    description: [
      {
        subTitle: "Anforderungen zum Schwimmabzeichen Bronze",
        about: `dieser ist ein Kurs für diejenigen, die bereits schwimmen können und das Seepferdchen 
        Schwimmabzeichen besitzen.
        Jedes Kind muss sich ohne Hilfsmittel über Wasser halten können. 
       im Bronzekurs werden die Kinder ihre bereits erlernten Fähigkeiten  verbessern und Kondition aufbauen, 
       vor allem das Brustschwimmen wird gefestigt.
       erst mit dem Erwerb des DSA Bronze gilt ihr Kind als sichere/r Schwimmer/in.`,
        rouls: [
          ` Sprung Kopfwärts vom Beckenrand 
      und 15 Minuten Schwimmen. In dieser Zeit 
      sind mindestens 200 m zurückzulegen, 
      davon 150 m in Bauch- oder Rückenlage 
      in einer erkennbaren Schwimmart und 50 m 
      in der anderen Körperlage 
      (Wechsel der Körperlage während des 
        Schwimmens auf der Schwimmbahn ohne Festhalten)`,
          `einmal ca. 2 m Tieftauchen 
        von der Wasseroberfläche und 
        einen Tauchring heraufholen`,
          `ein Paketsprung vom Startblock oder 1 m- Brett`,
          `Baderegeln`,
        ],
      },
      {
        subTitle: "Anforderungen zum Silber",
        about: `Bei den Silber-und Goldkurse, wird  das Gelerntes nochmal vertieft.
        Der Kurs ist anspruchsvoller, d.h. hier werden z.B. Rückenschwimmen und Kraulen erlernt und geübt, 
        sowie Streckentauchen. Schwimmsicherheit, Ausdauer und Muskelkraft werden weiter ausgebaut.
        In unseren Silber- und Goldkurse dürfen Kinder ab 9 Jahren teilnehmen.`,
        rouls: [
          `Sprung Kopfwärts vom Beckenrand und 20 Minuten Schwimmen. In dieser Zeit sind mindestens 
      400 m zurückzulegen, davon 300 m in Bauch- oder Rückenlage in einer erkennbaren Schwimmart und 100 
      m in der anderen Körperlage ( Wechsel der Körperlage während des Schwimmens auf der Schwimmbahn 
        ohne Festhalten)`,
          `zweimal ca. 2 m Tieftauchen von der Wasseroberfläche mit Heraufholen je eines 
        Gegenstandes (z.B kleiner Tauchring)`,
          `10 m Streckentauchen mit Abstoßen vom Beckenrand im Wasser.`,
          `Sprung aus 3 m Höhe oder zwei verschiedene Sprünge aus 1 m Höhe`,
        ],
      },
      {
        subTitle: "Anforderungen zum Goldkurse",
        about: "",
        rouls: [
          `Sprung Kopfwärts vom Beckenrand und 30 Minuten Schwimmen. In dieser Zeit sind mindestens 
      800 m zurückzulegen, davon 650 m in den Bauch- oder Rückenlage in einer erkennbaren Schwimmart und 
      150 m in der anderen Körperlage (Wechsel der Körperlage während des Schwimmens auf der Schwimmbahn 
        ohne Festhalten)`,
          `Startsprung und 25 m Kraulschwimmen`,
          `Startsprung und 50 m Brustschwimmen in 
        höchstens 1:15 Minuten
        `,
          `50 m Rückenschwimmen mit Grätschschwung ohne Armtätigkeit oder Rückenkraulschwimmen`,
          `10 m streckentauchen aus der Schwimmlage (ohne Abstoßen vom Beckenrand)`,
          `Dreimal ca. 2m Tieftauchen von der Wasseroberfläche mit Heraufholen je eines Gegenstandes 
        (z.B.: kleiner Tauchring) innerhalb von 3 Minuten`,
          `ein Sprung aus 3 m Höhe oder zwei verschiedene Sprünge aus 1 m Höhe`,
          ` 50 m Transport schwimmen: Schieben oder Ziehen`,
        ],
      },
    ],
  },
  {
    id: 4,
    number: "04",
    imgSrc: img4,
    destTitle: "Jahreskurs",
    age: "ab 6 Jahre, Mindestanforderung Schwimmabzeichen Silber",
    duration1: "45 Minuten",
    lessons: "Das ganze Jahr, Monatlich kündbar",
    kids: "von 8-12 Kinder",
    preis1: "4 Wochen Training (1x pro Woche): Preis 40",
    description: [
      {
        subTitle: "Die Prüfungsanforderungen sind:(Juniorretter)",
        about: `Für unsere motiviertesten  Schwimmer/in besteht die Möglichkeit an 
        einem Schwimmkurs für das ganze Jahr  teilzunehmen. 
        Die Mindestanforderung ist das Silberschwimmabzeichen.
        Die Ziele in diesem Jahreskurs:
        Die Kursteilnehmer erlernen das 
        Kraul-und Rückenkraulschwimmen, um das Goldschwimmabzeichen zu erwerben.
        Außerdem dient diesen Kurs als Vorbereitung für den Juniorretter/in ( Praktische Teil).`,
        rouls: [
          `1)  100m schwimmen ohne Unterbrechung, davon`,
          `. 25 m Kraulschwimmen`,
          `. 25m Rückenkraulschwimmen`,
          `. 25 Brustschwimmen`,
          `. 25 Rückenschwimmen mit Grätschschwung ohne Armtätigkeit`,
          ` 2) 25m schleppen eines Partners mit Achselschleppgriff`,
          ` 3)  Selbstrettungsübung: kombinierter Übung in leichter Freizeitbekleidung die 
          ohne Pause, in der angegebenen Reihenfolge zu erfüllen ist:`,
          `. Fußwärts ins Wasser springen, danach schwebelage einnehmen`,
          `. 4 Minuten schweben an der Wasseroberfläche in Rückenlage mit Paddelbewegungen`,
          `. 6 Minuten langsames Schwimmen, jedoch mindestens vier mal die Körper Lage wechseln 
          (Bauch, Rücken, Seitenlage)`,
          `. Anschließend die Kleidungsstücke im tiefen Wasser ausziehen`,
          ` 4) Fremdrettungsübung: kombinierte Übung, die ohne Pause  in der angegebenen Reihenfolge 
          zu erfüllen ist:`,
          `. 15 m in Bauchlage an schwimmen nach halber Strecke Kopfschmerz auf circa 2 m Tiefe abtauchen 
          und zwei kleine Gegenstände (z.B. Tauchringe) heraufholen, diese anschließend fallen lassen und 
          das Anschwimmen fortsetzen.`,
          `. 15 m schleppen eines Partners mit Achselschleppgriff`,
          `. Sichern des Geretteten`,
        ],
      },
    ],
  },
  {
    id: 5,
    number: "05",
    imgSrc: img5,
    destTitle: "AquaFitness",
    age: "",
    duration1: "45 Minuten",
    lessons: "10 Trainingseinheiten",
    kids: "12-16 Teilnehmer",
    // preis1: "Preis 120",
    description: [
      {
        subTitle: "AquaFit: schonendes Ganzkörper Workout: ",
        about: ``,
        rouls: [
          `Das Ganzkörpertraining ist besonders schonend für deine Gelenke. Daher kann auch jede/r an den Trainingseinheiten teilnehmen. Ob Frauen oder Männer, trainiert oder untrainiert, Senior/innen etc., alle Erwachsenen sind in unseren AquaFitnesskursen willkommen!`,
        ],
      },
      {
        subTitle: "Welche Vorteile hat AquaFitness",
        about: ``,
        rouls: [
          `Viel mehr als nur Gymnastik im Wasser: AquaFitness ist ein effektives Ganzkörpertraining mit Fun-Faktor für deine Gesundheit! 
          `,
        ],
      },
      {
        subTitle: "Alle Vorteile der Aqua-Fitnesskurse im Überblick:",
        about: ``,
        rouls: [
          `kräftigen deinen gesamten Körper `,
          `effektiv und schonend
          trainieren dein Herz-Kreislauf-System und verbessern deine Ausdauer
          `,
          `ideal zum Abnehmen`,
          `Wasserauftrieb entlastet deine Sehnen, Bänder, Gelenke und Wirbelsäule: Du trägst nur ca. 10 % deines Körpergewichts selbst.
          `,
          `kurbeln deine Durchblutung an, `,
          `fördern deinen Gleichgewichtssinn, sowie deine Koordination`,
          `und natürlich viel Spaß.`,
        ],
      },
      {
        subTitle: "Dauer der Kurse:",
        about: ``,
        rouls: [
          ` Rein ins Wasser und los geht's: Die AquaFitnesskurse dauern 45 Minuten. Du trainierst in hüft- oder brusthohem Wasser mit Schwimmnudeln, Hanteln oder Schwimmbrettern und deinem eigenen Körpergewicht.`,
          `Ich helfe dir, die Bewegungen und Übungen im Wasser richtig auszuführen und mit Freude im Wasser zu trainieren.`,
        ],
      },
      {
        subTitle: "Für wen ist AquaFitness geeignet? ",
        about: ``,
        rouls: [
          ` Dank des Auftriebs, den das Wasser erzeugt, ist AquaFitness nicht nur hocheffektiv, sondern auch besonders wenig belastend. Daher eignet sich das wirkungsvolle Aquatraining insbesondere für`,
          `Menschen mit Wirbelsäulen- und Gelenksproblemen (z. B. im Knie)`,
          `Menschen mit Übergewicht`,
          `Senior/innen`,
          `Sportler/innen zur Rehabilitation nach Verletzungen`,
          `Aber auch, wenn du einfach mal Abwechslung in dein Training bringen willst, ist AquaFitness eine tolle Alternative für mehr Bewegung im Alltag.`,
        ],
      },
      {
        subTitle: "",
        about: `Bei wenigen Anmeldungen findet der Kurs nicht statt.`,
        rouls: [
          // ` Dank des Auftriebs, den das Wasser erzeugt, ist AquaFitness nicht nur hocheffektiv, sondern auch besonders wenig belastend. Daher eignet sich das wirkungsvolle Aquatraining insbesondere für`,
          // `Menschen mit Wirbelsäulen- und Gelenksproblemen (z. B. im Knie)`,
          // `Menschen mit Übergewicht`,
          // `Senior/innen`,
          // `Sportler/innen zur Rehabilitation nach Verletzungen`,
          // `Aber auch, wenn du einfach mal Abwechslung in dein Training bringen willst, ist AquaFitness eine tolle Alternative für mehr Bewegung im Alltag.`,
        ],
      },
    ],
  },
];
